import React, { useReducer } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import InputV2 from '../shared/InputV2/InputV2';
import { validCNPJ } from '../Utils/Index';
import { toast } from 'react-toastify';

// Validation schema
const schema = Yup.object().shape({
  isRegistered: Yup.string().required('Selecione uma opção.'),
  importMethod: Yup.string().required('Selecione um método de importação.'),
  ifoodLink: Yup.string()
    .url('Insira um link válido.')
    .required('O link do cardápio é obrigatório.'),
});

export default function IfoodIntegration() {
  const integrationModes = [
    {
      name: 'byLink',
      label: 'Através link do meu cardápio',
    },
    {
      name: 'byDocument',
      label: 'Pelo CNPJ do meu estabelecimento',
    },
  ];

  const [integration, updateIntegration] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      byLink: true,
      byDocument: false,
      storeValue: '',
    }
  );

  function handleIntegrationMode(mode) {
    for (const key in integration) {
      if (key !== 'enable' && key !== mode) integration[key] = false;
    }
    integration[mode] = true;
    updateIntegration(integration);
  }

  function handleSubmit(data) {
    if (!isValidStoreInfo()) {
      toast.error('CNPJ inválido.');
      return;
    }
  }

  function isValidStoreInfo() {
    if (integration.byDocument) return validCNPJ(integration.storeValue);
    return true;
  }

  return (
    <div className="hstack justify-content-center py-3">
      <div
        className="align-items-center p-2 vstack"
        style={{ maxWidth: '600px' }}
      >
        <h1 className="fs-3 fw-bold text-red-rose">
          Importe seu cardápio do iFood
        </h1>
        <h2 className="fs-6">
          Importe seu cardápio do iFood para o seu estabelecimento no
          DeliveryLegal.
        </h2>

        <Form schema={schema} onSubmit={handleSubmit} className="w-100 vstack">
          <div className="vstack gap-2 py-3">
            <h2 className="fs-5 fw-bold">
              Como deseja importar seu cardápio ?
            </h2>
            {integrationModes.map((mode) => (
              <button
                key={mode.name}
                type="button"
                className={`btn fw-bold h-100 py-3 w-100 ${
                  integration[mode.name] ? 'btn-primary' : 'btn-outline-danger'
                }`}
                onClick={() => handleIntegrationMode(mode.name)}
              >
                {mode.label}
              </button>
            ))}
          </div>

          <h2 className="fs-5 fw-bold mb-2">
            {integration.byLink
              ? 'Informe o link do seu cardápio iFood'
              : 'Informe o CNPJ do seu estabelecimento'}
          </h2>

          <InputV2
            label=""
            type="url"
            name="ifoodLink"
            className="form-control"
            placeholder={
              integration.byLink
                ? 'https://www.ifood.com.br/...'
                : 'CNPJ do estabelecimento'
            }
            disabled={!integration.byLink && !integration.byDocument}
            onUpdateValue={(storeValue) => updateIntegration({ storeValue })}
          />

          {/* Actions */}
          <button
            type="submit"
            className="btn btn-primary w-100 my-3"
            disabled={!integration.storeValue}
          >
            Enviar
          </button>
        </Form>
      </div>
    </div>
  );
}
